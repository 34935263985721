import { ColorVariant } from "@/config";
import { useAppStore } from "@/stores/appStore";
import { capitalize } from "@/utils";
import { createFileRoute } from "@tanstack/react-router";
import styled from "styled-components";

const colors: Record<ColorVariant, string> = {
	anthracite: "https://www.telluria.eu/media/widgetkit/antraciet-c4366d5305c2188af3639a67a82d1442.jpg",
	grey: "https://www.telluria.eu/media/widgetkit/grey-c4366d5305c2188af3639a67a82d1442.jpg",
	duo: "https://www.telluria.eu/media/widgetkit/white-2-9bd4464326f2a4be18d161add6819829.jpg",
	white: "https://www.telluria.eu/media/widgetkit/white-ecfec3b603090c2f7c4c1ee34ffcc8e2.jpg",
};

const List = styled.ul`
	list-style: none;
	display: grid;
	grid-template-columns: repeat(2, auto);

	button {
		all: unset;
		width: 4rem;

		&:hover {
			cursor: pointer;
		}
	}

	img {
		width: 100%;
		object-fit: contain;
	}
`;

export const Route = createFileRoute("/_configurator/colors")({
	component: Colors,
});

function Colors() {
	const update = useAppStore((store) => store.update);

	return (
		<List>
			{Object.entries(colors).map(([name, link]) => (
				<li key={name}>
					<button
						title={capitalize(name)}
						onClick={() => {
							const wallColor = name === "duo" ? "white" : (name as "white" | "anthracite" | "grey");
							const pillarColor = name === "white" ? "white" : "anthracite";

							update({ wallColor, pillarColor });
						}}>
						<img alt={name} src={link} />
					</button>
				</li>
			))}
		</List>
	);
}
