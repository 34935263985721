type Locale = "en" | "nl";

export const DEFAULT_LOCALE = "nl";

const translations = {
	left: {
		en: "Left",
		nl: "Links",
	},
	right: {
		en: "Right",
		nl: "Rechts",
	},
	front: {
		en: "Front",
		nl: "Voor",
	},
	back: {
		en: "Back",
		nl: "Achter",
	},
	walls: {
		en: "Walls",
		nl: "Wanden",
	},
	roof: {
		en: "Roof",
		nl: "Dak",
	},
	colors: {
		en: "Colors",
		nl: "Kleuren",
	},
	summary: {
		en: "Summary",
		nl: "Overzicht",
	},
	selectWall: {
		en: "Please select a wall",
		nl: "Gelieve een wand te selecteren",
	},
	requestQuote: {
		en: "Request quote",
		nl: "Offerte aanvragen",
	},
	hideWalls: {
		en: "Hide walls",
		nl: "Wanden verbergen",
	},
	showWalls: {
		en: "Show walls",
		nl: "Wanden tonen",
	},
	hideGrid: {
		en: "Hide grid",
		nl: "Raster verbergen",
	},
	showGrid: {
		en: "Show grid",
		nl: "Raster tonen",
	},
	hideMeasurements: {
		en: "Show measurements",
		nl: "Afmetingen verbergen",
	},
	showMeasurements: {
		en: "Show measurements",
		nl: "Afmetingen tonen",
	},
} satisfies Record<string, Record<Locale, string>>;

export default translations;
