import { selectors, useAppStore } from "@/stores/appStore";
import translations, { DEFAULT_LOCALE } from "@/translations";
import { isNotNullOrUndefined } from "@/utils";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_configurator/walls")({
	component: Walls,
});

function Select() {
	const setSelectedWall = useAppStore((store) => store.setSelectedWall);
	const selectedWall = useAppStore(selectors.getWallBySelected);
	const options = useAppStore(selectors.getAvailableWallsBySelected);

	return (
		<select value={selectedWall?.id} onChange={(event) => setSelectedWall(event.target.value)}>
			{options.map(
				(option) =>
					isNotNullOrUndefined(option) && (
						<option key={option.id} value={option.id}>
							{option.id}
						</option>
					)
			)}
		</select>
	);
}

function Walls() {
	const selectedWall = useAppStore((store) => store.selectedWall);
	return (
		<div>
			<p>{selectedWall ?? translations.selectWall[DEFAULT_LOCALE]}</p>
			{isNotNullOrUndefined(selectedWall) ? <Select /> : null}
		</div>
	);
}
