import { ReactNode } from "react";
import styled from "styled-components";
import Steps from "./Steps";

type SidepanelProps = {
	children: ReactNode;
};

export default function Sidepanel({ children }: SidepanelProps) {
	return (
		<SidepanelContainer>
			<Steps />
			<SidepanelContent>{children}</SidepanelContent>
		</SidepanelContainer>
	);
}

const SidepanelContainer = styled.div`
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	height: 100%;
	min-width: min(100%, 400px);
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.white};
`;

const SidepanelContent = styled.div`
	width: 100%;
	padding: 1rem;
`;
