import CONFIG from "@/config";
import { selectors, useAppStore, WallLocation } from "@/stores/appStore";
import { GroupProps } from "@react-three/fiber";
import { FunctionComponent, lazy } from "react";
import EmptyWall from "./23FA-00";
import { isLeftDoorSegment, isWindowSegment } from "@/utils";
import { useGLTF } from "@react-three/drei";
import useColors from "@/hooks/useColors";

const Window = lazy(() => import("./SW"));
const Door = lazy(() => import("./DL"));

interface WallProps extends GroupProps {
	location: WallLocation;
}

const Wall: FunctionComponent<WallProps> = ({ location, ...otherProps }) => {
	const wall = useAppStore(selectors.getWallByLocation(location));
	const wallColor = useAppStore(selectors.getWallColor);
	const { materials } = useGLTF("/models/23FA-00.glb");

	useColors(materials, {
		["Wall -Exterior"]: wallColor,
		["Wall - Skirting"]: CONFIG.colors.anthracite,
	});

	if (!wall) return null;

	const emptyWallSize = CONFIG.wallSizes.inner.S;
	const scalingFactor = wall.innerWidth / emptyWallSize;
	const hasLeftDoor = wall.segments.some(isLeftDoorSegment);
	const hasWindow = wall.segments.some(isWindowSegment);

	return (
		<EmptyWall {...otherProps} location={location} scale-x={scalingFactor}>
			{hasWindow && <Window position-y={CONFIG.windowHeight} scale-x={1 / scalingFactor} />}
			{hasLeftDoor && <Door position-y={CONFIG.doorStepHeight} scale-x={1 / scalingFactor} />}
		</EmptyWall>
	);
};

export default Wall;
