import { Billboard, Line, LineProps, Text } from "@react-three/drei";
import { ReactNode, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Box3, Group, Vector3 } from "three";

interface MeasuredProps extends Omit<LineProps, "points"> {
	offset?: number;
	showX?: boolean;
	showY?: boolean;
	showZ?: boolean;
	render?: (distance: number) => ReactNode;
}

export default function Measured({
	children,
	visible,
	offset = 0.1,
	showX = true,
	showY = true,
	showZ = true,
	...props
}: MeasuredProps) {
	const groupRef = useRef<Group>(null);
	const [boundingBox, setBoundingBox] = useState<Box3>(() => new Box3());

	useLayoutEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		setBoundingBox(new Box3().setFromObject(groupRef.current!));
	}, [children, offset]);

	const bounds = useMemo(() => {
		const from = boundingBox.min.clone();

		return {
			x: {
				from,
				to: new Vector3(boundingBox.max.x, from.y, from.z),
				normal: new Vector3(0, 0, -1).multiplyScalar(offset),
			},
			y: {
				from,
				to: new Vector3(from.x, boundingBox.max.y, from.z),
				normal: new Vector3(-1, 0, -1).normalize().multiplyScalar(offset),
			},
			z: {
				from,
				to: new Vector3(from.x, from.y, boundingBox.max.z),
				normal: new Vector3(-1, 0, 0).multiplyScalar(offset),
			},
		};
	}, [offset, boundingBox]);

	return (
		<>
			<group ref={groupRef}>{children}</group>
			<group visible={visible}>
				{showX && <MeasureLine {...bounds.x} {...props} />}
				{showY && <MeasureLine {...bounds.y} {...props} />}
				{showZ && <MeasureLine {...bounds.z} {...props} />}
			</group>
		</>
	);
}

interface MeasureLineProps extends Omit<MeasuredProps, "showX" | "showY" | "showZ"> {
	from: Vector3;
	to: Vector3;
	normal: Vector3;
}

function MeasureLine({ from, to, normal, render = defaultRender, ...props }: MeasureLineProps) {
	const { points, mid, distance } = useMemo(() => {
		const mid = new Vector3().addVectors(from, to).multiplyScalar(0.5).add(normal);
		const distance = from.distanceTo(to);

		return { points: [from, to], mid, distance };
	}, [from, normal, to]);

	if (isNaN(distance)) return null;

	return (
		<group position={normal}>
			<Line points={points} {...props} />
			<Billboard position={mid}>{render(distance)}</Billboard>
		</group>
	);
}

function defaultRender(distance: number) {
	return (
		<Text fontSize={0.1} color="black">
			{`${distance.toFixed(2)}m`}
		</Text>
	);
}
