/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 public/models/23FA-00.glb --types --exportdefault --root public --precision 6 
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { WallLocation } from "@/stores/appStore";
import { Geometry, Base } from "@react-three/csg";
import { GroupProps } from "@react-three/fiber";

type GLTFResult = GLTF & {
	nodes: {
		["23FA-Beams"]: THREE.Mesh;
		["23FA-Exterior"]: THREE.Mesh;
		["23FA-Interior"]: THREE.Mesh;
		["23FA-Sides"]: THREE.Mesh;
		["23FA-Skirting"]: THREE.Mesh;
	};
	materials: {
		["Wall - Beams"]: THREE.MeshStandardMaterial;
		["Wall -Exterior"]: THREE.MeshStandardMaterial;
		["Wall - Interior"]: THREE.MeshStandardMaterial;
		["Wall - Sides"]: THREE.MeshStandardMaterial;
		["Wall - Skirting"]: THREE.MeshStandardMaterial;
	};
};

interface EmptyWallProps extends GroupProps {
	location: WallLocation;
}

export default function EmptyWall({ children, location, ...props }: EmptyWallProps) {
	const { nodes, materials } = useGLTF("/models/23FA-00.glb") as GLTFResult;

	return (
		<group {...props} dispose={null}>
			<mesh material={materials["Wall -Exterior"]} userData={{ location }}>
				<Geometry>
					<Base geometry={nodes["23FA-Exterior"].geometry} />
					{children}
				</Geometry>
			</mesh>
			<mesh material={materials["Wall - Beams"]} userData={{ location }}>
				<Geometry>
					<Base geometry={nodes["23FA-Beams"].geometry} />
					{children}
				</Geometry>
			</mesh>
			<mesh material={materials["Wall - Interior"]} userData={{ location }}>
				<Geometry>
					<Base geometry={nodes["23FA-Interior"].geometry} />
					{children}
				</Geometry>
			</mesh>
			<mesh geometry={nodes["23FA-Sides"].geometry} material={materials["Wall - Sides"]} userData={{ location }} />
			<mesh material={materials["Wall - Skirting"]} userData={{ location }}>
				<Geometry>
					<Base geometry={nodes["23FA-Skirting"].geometry} />
					{children}
				</Geometry>
			</mesh>
		</group>
	);
}

useGLTF.preload("/models/23FA-00.glb");
