import { SegmentCode } from "@/config";
import { Material, MeshStandardMaterial } from "three";

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.substring(1);

export const isNull = (input: unknown): input is null => input === null;
export const isUndefined = (input: unknown): input is undefined => input === undefined;
export const isNullOrUndefined = (input: unknown): input is null | undefined => isNull(input) || isUndefined(input);
export const isNotNullOrUndefined = <T,>(input: T | null | undefined): input is T => !isNullOrUndefined(input);

export const isMaterial = (input: unknown): input is Material =>
	typeof input === "object" && (input as Material).isMaterial;

export const isMeshStandardMaterial = (input: unknown): input is MeshStandardMaterial =>
	typeof input === "object" && (input as MeshStandardMaterial).isMeshStandardMaterial;

export const sortInBuckets = <T extends object>(source: T[], key: keyof T): Record<string, T[]> =>
	source.reduce<Record<string, T[]>>((acc, item) => {
		const value = item[key];

		if (!value) {
			return acc;
		}

		const bucketKey = String(value);
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (!acc[bucketKey]) {
			acc[bucketKey] = [];
		}
		acc[bucketKey].push(item);

		return acc;
	}, {});

export const isWindowSegment = (segmentCode: SegmentCode): segmentCode is "16SW" | "23SW" =>
	["16SW", "23SW"].includes(segmentCode);

export const isLeftDoorSegment = (segmentCode: SegmentCode): segmentCode is "16DL" | "23DL" =>
	["16DL", "23DL"].includes(segmentCode);
