import { create, StateCreator } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { createJSONStorage, persist } from "zustand/middleware";
import CONFIG, { Roof, RoofCode, Wall, WallCode } from "@/config";
import { isNotNullOrUndefined } from "@/utils";
import { Color } from "three";

const isDevMode = import.meta.env.DEV;

export type Side = "left" | "center" | "right";
export type WallLocation = "left" | "right" | "front" | "back";

type State = {
	side: Side;
	roof: RoofCode;
	walls: Record<WallLocation, WallCode | undefined>;
	selectedWall?: WallLocation;
	wallColor: "grey" | "anthracite" | "white";
	pillarColor: "anthracite" | "white";
	showGrid: boolean;
	showWalls: boolean;
	showMeasurements: boolean;
	isDevMode: boolean;
};

type Actions = {
	setRoof: (roofCode: RoofCode) => void;
	setWall: (location: WallLocation, wall: WallCode) => void;
	setSelectedWall: (wall: WallCode) => void;
	update: (options: Partial<State>) => void;
};

export const getRoofById = (id: RoofCode) => CONFIG.roofs.byId[id];
export const getWallById = (id: WallCode) => CONFIG.walls.byId[id];
export const getWallsByWidth = (() => {
	const EMPTY_LIST: Wall[] = [];
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	return (width: number) => CONFIG.walls.byWidth[width] ?? EMPTY_LIST;
})();

const store: StateCreator<State & Actions> = (set) => ({
	isDevMode,
	side: "center",
	showGrid: true,
	showWalls: true,
	showMeasurements: false,
	roof: CONFIG.defaultRoof.id,
	selectedWall: undefined,
	wallColor: "anthracite",
	pillarColor: "anthracite",
	walls: {
		left: getWallsByWidth(CONFIG.defaultRoof.depth)[0]?.id,
		right: getWallsByWidth(CONFIG.defaultRoof.depth)[0]?.id,
		front: getWallsByWidth(CONFIG.defaultRoof.width)[0]?.id,
		back: getWallsByWidth(CONFIG.defaultRoof.width)[0]?.id,
	},

	setRoof: (roofCode) => {
		const roof = getRoofById(roofCode);
		const [leftRight] = getWallsByWidth(roof.depth);
		const [frontBack] = getWallsByWidth(roof.width);

		set((state) => ({
			...state,
			roof: roofCode,
			walls: {
				left: leftRight.id,
				right: leftRight.id,
				front: frontBack.id,
				back: frontBack.id,
			},
		}));
	},

	setWall: (location, wall) => set((state) => ({ ...state, walls: { ...state.walls, [location]: wall } })),
	setSelectedWall: (wall) =>
		set((state) => (state.selectedWall ? { ...state, walls: { ...state.walls, [state.selectedWall]: wall } } : state)),
	update: (options) => set((state) => ({ ...state, ...options })),
});

export const useAppStore = !isDevMode
	? create(
			persist<State & Actions>(store, {
				name: "app-store",
				version: 1,
				storage: createJSONStorage(() => sessionStorage),
			})
		)
	: create(store);

useAppStore.getState().setRoof(CONFIG.defaultRoof.id);

if (isDevMode) {
	mountStoreDevtool("AppStore", useAppStore);
}

export const selectors = {
	getRoofBySelected: (state: State): Roof => {
		return getRoofById(state.roof);
	},

	getWallBySelected: (state: State): Wall | undefined => {
		const wallId = state.selectedWall && state.walls[state.selectedWall];
		return isNotNullOrUndefined(wallId) ? getWallById(wallId) : undefined;
	},

	getAvailableWallsByLocation: (location?: WallLocation) => {
		return (state: State): (Wall | undefined)[] => {
			const selectedRoof = selectors.getRoofBySelected(state);

			return location === "front" || location === "back"
				? getWallsByWidth(selectedRoof.width)
				: location === "left" || location === "right"
					? getWallsByWidth(selectedRoof.depth)
					: [];
		};
	},

	getAvailableWallsBySelected: (state: State) => {
		return selectors.getAvailableWallsByLocation(state.selectedWall)(state);
	},

	getWallByLocation: (location: WallLocation) => {
		return (state: State): Wall | undefined => {
			const wallId = state.walls[location];
			return isNotNullOrUndefined(wallId) ? getWallById(wallId) : undefined;
		};
	},

	getPillarColor: (state: State): Color => CONFIG.colors[state.pillarColor],
	getWallColor: (state: State): Color => CONFIG.colors[state.wallColor],
};
